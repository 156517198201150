import React from 'react';
import get from 'lodash/get';
import { Helmet } from 'react-helmet';
import './learning-page.scss';
import { graphql } from 'gatsby';
import {
  ILearningPageHeader,
  ILearningResource,
} from '../queries-intefaces/ilearning-page';
import { ILocation } from '../models/ilocation';
import loadable from '@loadable/component';

interface ILearningPageTemplate {
  data: {
    allContentfulLearningPage: { nodes: Array<ILearningPageHeader> };
    allContentfulLearningResources: { nodes: Array<ILearningResource> };
    site: {
      siteMetadata: {
        title: string;
      };
    };
  };
  location: ILocation;
}

interface ILearningPageState {
  type: string;
  resources: Array<ILearningResource>;
}

export class LearningPageTemplate extends React.Component<
  ILearningPageTemplate,
  ILearningPageState
> {
  constructor(props: ILearningPageTemplate) {
    super(props);
    this.state = { type: '', resources: [] };
    this.resourcesFilter = this.resourcesFilter.bind(this);
  }

  showByMostPopular(resources: ILearningResource[]) {
    return resources
      .filter(value => value.promote === true)
      .sort((a, b) => {
        return b.order - a.order;
      });
  }

  showByType(resources: ILearningResource[], type: string) {
    return resources.filter(value => {
      return value.type === type;
    });
  }

  resourcesFilter(type?: string) {
    const data = this.props.data?.allContentfulLearningResources?.nodes;
    let resources : ILearningResource[] = [];
    if (!type || type === 'most') {
      resources = this.showByMostPopular(data);
    } else if (type === null) {
      resources = data;
    } else {
      resources = this.showByType(data, type);
    }

    this.setState({
      resources: resources,
      type: !type ? 'most' : type,
    });
  }

  componentDidMount() {
    this.resourcesFilter();
  }

  render() {
    const Layout = loadable(() => import ('../components/layout'));
    const HeaderLearning = loadable(() => import ('../components/learning-page/header-learning/header-learning'));
    const BookCard = loadable(() => import ('../components/learning-page/book-card/book-card'));

    const headerData = this.props.data?.allContentfulLearningPage?.nodes[0];
    const siteTitle = this.props.data?.site?.siteMetadata.title;
    const [footerData] = get(this, 'props.data.allContentfulFooterSection.nodes');
    const [headerPageData] = get(this, 'props.data.allContentfulHeaderSection.nodes');
    return (
      <Layout className="dark-style" footer={footerData} header={headerPageData}>
        <div className="learning-page">
        <Helmet title={`${headerData.title} | ${siteTitle}`} />
          <HeaderLearning
            title={headerData.title}
            subTitle={headerData.subTitle}
            resourcesFilter={this.resourcesFilter}
            type={this.state.type}
            booksText={headerData?.booksText}
            coursesText={headerData?.coursesText}
            mostPopularText={headerData?.mostPopularText}
            podcastsText={headerData?.podcastsText}
          />
          <div className="book1"></div>
          {this.state.resources.map((value, key) => {
            const className = key % 2 === 0 ? 'book3' : 'book2';
            if (key === 0) {
              return (
                <div key={key} className="first-resource container">
                  <BookCard data={value} />
                </div>
              );
            }
            return (
              <div key={key} className={className}>
                <div className="container">
                  <BookCard data={value} />
                </div>
              </div>
            );
          })}
        </div>
      </Layout>
    );
  }
}
export default LearningPageTemplate;

export const learningPageQuery = graphql`
  {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulLearningPage {
      nodes {
        id
        title
        subTitle
        booksText
        coursesText
        mostPopularText
        podcastsText
      }
    }
    allContentfulLearningResources {
      nodes {
        author
        date
        promote
        title
        type
        order
        image {
          file {
            url
          }
        }
        content {
          childMarkdownRemark {
            html
          }
        }
        buttonLabel
        link
      }
    }
    allContentfulFooterSection(limit: 1){
      nodes {
        icon {
          id
          fluid {
            src
            base64
          }
          file {
            url
          }
        }
        description {
          childMarkdownRemark{
            html
          }
        }
        copyright {
          childMarkdownRemark{
            html
          }
        }
        socialMediaLinks{
          title
          linkUrl
          showLink
        }
        links{
          title
          linkUrl
          showLink
        }
      }
    }
    allContentfulHeaderSection(limit: 1){
      nodes {
        title
        leftLinks{
          title
          linkUrl
          showLink
        }
        rightLinks{
          title
          linkUrl
          showLink
        }
      }
    }
  }
`;
